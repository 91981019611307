
export default {
    name: 'UserNavMobile',
    props: {
        usertype: {
            type: String,
            default: ''
        }
    },
    methods: {
        async logout() {
            await this.$store.dispatch('user/logoutUser')
            await this.$store.dispatch('cart/deleteAllProducts')
            await this.$store.dispatch('utils/openNotificationModal', {
                'message': 'logout-successful-message',
                'type': 'success'
            })
            this.$router.push({'name': 'Index'});
        }
    },
    mounted() {
    }
}