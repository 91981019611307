<script >
import Button from "@/components/Common/Buttons/Button/Button.vue";
export default {
  name: 'CookiesModal',
  components: {
    Button
  },
  data() {
    return {
      showModal: true,
    };
  },
  mounted() {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted) {
      this.showModal = false;
    }
  },
  methods: {
    hideModal() {
      this.showModal = false;
      localStorage.setItem('cookiesAccepted', 'true');
    },
  },
}
</script>

<template>
  <div v-if="showModal" class="modal-wrapper">
    <div class="row">
      <div class="row col-md-10">
        <div class="col-md-6 mb-4 mb-md-0">
          <div class="cookies-modal-title"> {{$t('cookies-modal-title-left')}} </div>
          <div class="cookies-modal-paragraph"> {{$t('cookies-modal-paragraph-left')}} </div>
        </div>
        <div class="col-md-6">
          <div class="cookies-modal-title"> {{$t('cookies-modal-title-right')}} </div>
          <div class="cookies-modal-paragraph"> {{$t('cookies-modal-paragraph-right')}} </div>
        </div>
      </div>
      <div class="button-container col-md-2">
        <div class="">
          <Button
              class="mb-3 mt-3 mt-md-0"
              :theme="'solid-orange'"
              :text="$t('accept')"
              :size="'small'"
              @click="hideModal"
          />
        </div>
        <router-link :to="{ name: 'PrivacyPolicyPage' }">
          <Button
              :theme="'outline-orange'"
              :text="$t('show-purposes')"
              :size="'small'"
              @click="hideModal"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/css/variables.scss";
    .modal-wrapper {
      background: #FFFFFF;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      z-index: 999999;
      .cookies-modal-title {
        @include subtitle-3;
      }
      .cookies-modal-paragraph {
        margin-top: $spacing-1;
        @include body-3;
      }
    }
    @media screen  and (min-width: 768px) {
      .modal-wrapper {
        padding: 50px;
      }
    }
</style>